import { SUCCESS, REQUEST, FAILURE } from '../../../common/utils/action-type-utils';
import { fetchIstInfoList, fetchIstInfoPkNmgt, insertIstInfoList } from '../../../lib/brc/brc0203v01.api';
import dayjs from 'dayjs';

// actions
const FETCH_ISTINFO = 'brcIstInfo/FETCH_ISTINFO';
const UPDATE_ISTINFO = 'brcIstInfo/UPDATE_ISTINFO';
const UPDATE_INIT = 'brcIstInfo/UPDATE_INIT';
const FETCH_ISTINFO_PK_NMGT = 'brcIstInfo/FETCH_ISTINFO_PK_NMGT';

const RESET_STATE = 'brcIstInfo/RESET_STATE';
const SAVE_SEARCH_FORM_VALUES = 'brcIstInfo/SAVE_SEARCH_FORM_VALUES';

export const initFormValues = {
  sch_gubun: '1', // 검색조건
  sch_txt: '', // 텍스트
  sch_sta_dt: dayjs().add(-6, 'month').format('YYYY.MM.DD'), // 작성기간
  sch_end_dt: dayjs().format('YYYY.MM.DD'), // 작성기간
  gb: '1',
};

// initial state
const initialState = {
  loading: false,
  success: false,
  message: '',
  list: [],
  totalCount: 0,
  updateLoading: false, // 등록/수정
  updateSuccess: false, // 등록/수정
  updateCount: false, // 등록/수정
  mainPk: '', // 채번
  mainPkCnt: 0, // 채번
  schGubunList: [
    { id: '1', label: '제목' },
    { id: '2', label: '제목+내용' },
    { id: '3', label: '작성자명' },
  ],
  searchFormValues: {
    init: true,
    ...initFormValues,
  },
};

// reducers
export default function brcIstInfo(state = initialState, action) {
  switch (action.type) {
    case SUCCESS(FETCH_ISTINFO): // 조회 성공
      return {
        ...state,
        success: true,
        loading: false,
        list: action.payload.data?.main,
        totalCount: action.payload.data?.main_cnt,
      };
    case REQUEST(FETCH_ISTINFO): // 조회 요청
      return {
        ...state,
        loading: true,
        success: false,
        message: '',
      };
    case FAILURE(FETCH_ISTINFO): // 조회실패
      return {
        ...initialState,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SUCCESS(UPDATE_ISTINFO): // 등록/수정 성공
      return {
        ...state,
        updateSuccess: true,
        updateLoading: false,
        updateCount: action.payload.data?.resultCnt,
      };
    case REQUEST(UPDATE_ISTINFO): // 등록/수정 요청
      return {
        ...state,
        updateLoading: true,
        updateSuccess: false,
        message: '',
      };
    case FAILURE(UPDATE_ISTINFO): // 등록/수정실패
      return {
        ...state,
        updateLoading: false,
        updateSuccess: false,
        message: action.payload.response?.data?.message,
      };
    case UPDATE_INIT: // 등록/수정실패 초기화
      return {
        ...state,
        updateLoading: false,
        updateSuccess: false,
      };
    case SUCCESS(FETCH_ISTINFO_PK_NMGT): // 채번 성공
      return {
        ...state,
        success: true,
        loading: false,
        mainPk: action.payload.data?.main_pk[0].pk,
        mainPkCnt: action.payload?.main_cnt,
      };
    case REQUEST(FETCH_ISTINFO_PK_NMGT): // 채번 요청
      return {
        ...state,
        loading: true,
        message: '',
      };
    case FAILURE(FETCH_ISTINFO_PK_NMGT): // 채번 실패
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload.response?.data?.message,
      };
    case SAVE_SEARCH_FORM_VALUES:
      return {
        ...state,
        // 콤보 - 기관
        searchFormValues: action.payload,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 금고영업점 - 지자체 정보관리 - 지자체 정보 등록 (조회)
 */
export const selectBrcIstInfoList = (searchParams) => {
  return {
    type: FETCH_ISTINFO,
    payload: fetchIstInfoList(searchParams),
  };
};

/**
 * 금고영업점 - 지자체 정보관리 - 지자체 정보 등록 (등록/수정)
 */
export const updateBrcIstInfoList = (dto) => {
  return {
    type: UPDATE_ISTINFO,
    payload: insertIstInfoList(dto),
  };
};

export const initUpdateBrcIstInfoList = () => {
  return {
    type: UPDATE_INIT,
  };
};

/**
 * 금고영업점 - 지자체 정보관리 - 지자체 정보 등록 pk채번
 */
export const selectBrcIstInfoPkNmgt = () => {
  return {
    type: FETCH_ISTINFO_PK_NMGT,
    payload: fetchIstInfoPkNmgt(),
  };
};

/**
 * 조회조건 저장
 */
export const saveSearchFormValues = (searchFormValues) => {
  return {
    type: SAVE_SEARCH_FORM_VALUES,
    payload: {
      ...searchFormValues,
      init: false,
    },
  };
};
/**
 * 초기화
 */
export const resetBrcIstInfo = () => {
  return {
    type: RESET_STATE,
  };
};
